//file: src/webpages/whatIsFreemasonry.js
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import SquareAndCompasses from "../images/square_and_compass.png";
import Header from "./header";

const WhatIsFreemasonry = () => {

    return (
        <div>
            <Header/>
            <Container>
                <Row>
                    <Col><p> </p></Col>
                </Row>
                <Row>
                    <Col  md={2} ><Image src={SquareAndCompasses} style={{ width: '12rem', height: '10rem' }}/></Col>
                    <Col md={10}>
                        <h1>What is Freemasonry</h1>
                        <br/>

                        <p align='left'>
                            The main principles of Freemasonry insist that each member
                            show tolerance, respect and kindness in his actions toward
                            others, practices charity and care for the community as a
                            whole, and strives to achieve high moral standards in his
                            own personal life.

                        </p>
                        <p align="left">
                            Honor and integrity are at the core of the Masonic belief
                            system. Members are obligated to practice self control
                            and treat the people around them with respect, regardless
                            of their own personal opinion of that person.

                        </p>
                        <p align="left">
                            Freemasonry is often seen as a secret society.  It isn't.  Its more acrutely described as a society with secrets which is very different.
                            Most of the secret information has been publicised in this, the 'Information' era. The important parts are often hidden in plain sight under
                            the deluge of missinformation and conspiricy theory so still remain contextually potent amongst bretheren.
                        </p>
                        <p align="left">
                            All that being said, Freemsanory has different meaning for all members.  Some enjoy the ritual and beaurocracy of the lodge and its meetings.
                            Some enjoy the social aspects, meeting to drink and dine amongst friends, visiting other lodges and meeting new people.  All bretheren enjoy
                            the sense of belonging, of having something seperate from their every day lives where they will always be accepted and treated like a brother
                            amongst fellow masons.
                        </p>

                    </Col>
                </Row>

            </Container>
            {/*{useQuery().get("val")}*/}
        </div>
    );
};
export default WhatIsFreemasonry;
//file: src/webpages/whereWeMeet.js
import React from 'react';
import {AmplifySignOut} from "@aws-amplify/ui-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import G from "../images/G.png";
import MarkMasonsBar from "../images/markMasonsBar.jpeg";
import MarkMasonsHall from "../images/markMasonsHall.png";
import MarkMasonsTemple from "../images/markMasonsTemple.png";
import FreemasonsHall from "../images/freemasonsHall.jpeg";
import FreemasonsHallGrandTemple from "../images/freemasonsHallGrandTemple.png";
import Header from "./header";


const WhereWeMeet = () => {
    return (
        <div>
            <Header/>
            <Container>
                <Row>
                    <Col><p> </p></Col>
                </Row>
                <Row>
                    <Col  md={3} >
                        <Image src={G} style={{ width: '15rem', height: '13rem' }}/>
                        <br/>
                    </Col>
                    <Col md={9}>
                        <h1>Where We Meet</h1>
                        <br/>
                        <Row>
                            <Col md={6}>
                                <p align="justify">
                                Let's start with the most important place first.  The Bar at Mark Mason's Hall.  At around 2 O'clock on the third Saturday of October, January,
                                March and May you'll generally find the members of Lewis Lodge here at the bar.  As you can see it's a beautiful, old fashioned bar with comfortable
                                leather seats and large sash windows looking out over St James St.  We'll have a couple of drinks, catch up with everybody, meet bretheren from other
                                lodges before going on to our meeting that starts at 3 O'clock.
                                </p>
                            </Col>
                            <Col md={6}>
                                <Image src={MarkMasonsBar} style={{ width: '15rem', height: '13rem' }}/>
                                <br/>
                                <p>The Bar at Mark Mason's Hall</p>
                            </Col>
                        </Row>
                        <br/>

                        <Row>
                            <Col md={6}>
                                <Image src={MarkMasonsHall} style={{ width: '15rem', height: '13rem' }}/>
                                <br/>
                                <p>Mark Mason's Hall</p>
                            </Col>
                            <Col md={6}>
                                <p align="justify">
                                Mark Mason's Hall is at 86 St James Street in the heart of Mayfair.  It has a long history as you'd expect, dating back to the early 18th
                                century.  The building is Grade II listed and consisting of seven levels.  Over the years its been used primarily as a Public House or as
                                a home to various clubs.  Mark Master Masons bought the lease in 1978 and after refurbishment it was formally opened in September 1979.

                                As members of the Lewis Lodge we're proud to meet there.  It's a beautiful building in an excellent state of repair.  As you enter the building
                                via a red carpeted hallway it opens out to a magnificent stair way which really gives you a sense of history and belonging.
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <p align="justify">
                                    At 3 O'clock or just before we'll go down to the Temple.  There are many temples in Mark Mason's Hall.  The one in the photograph is an example.
                                    The temples are very well appointed and are all similar to the one in the photo.  You'd need to be a member to understand what we do in the
                                    temple but rest assured it's nothing sinister.  In fact its the cumulation of many weeks of hard work followed by general lodge business.  We're
                                    normally in the temple for 2-3 hours before returning once more to the bar for a quick drink and then onto the Festive Board where we dine in style.
                                </p>
                            </Col>
                            <Col md={6}>
                                <Image src={MarkMasonsTemple} style={{ width: '15rem', height: '13rem' }}/>
                                <br/>
                                <p>A Temple at Mark Mason's Hall</p>
                            </Col>
                        </Row>
                        <br/>

                        <Row>
                            <Col md={6}>
                                <Image src={FreemasonsHall} style={{ width: '10rem', height: '13rem' }}/>
                                <br/>
                                <p>Grand Lodge London</p>
                            </Col>
                            <Col md={6}>
                                <p align="justify">
                                We hold our Lodge of Instruction (LOI) at Freemason's Hall on Great Queen St.  This stunning Art Deco building has many meeting rooms and temples that we
                                generally use to hold our annual General Purpose Meeting to discuss the annual business of the lodge and interview candidates.  We hold our LOIs here prior
                                to each meeting which is a sort of practice session that lets us all get together in a real temple and try things out.  Of course, we all meet up before and
                                after the AGM or LOI for a drink and a meal in one of the many pubs and restaurants that surround.
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <p align="justify">
                                From time to time there's an opportunity to attend a meeting at Grand Lodge in the Grand Temple.  These events are extremely popular as you can imagine so
                                early booking is a must.  You can always visit the Grand Temple as its open to visitors when its not in use but the ultimate way to experience it is as a
                                serving freemason at a real ceremony.
                                </p>
                            </Col>
                            <Col md={6}>
                                <Image src={FreemasonsHallGrandTemple} style={{ width: '15rem', height: '13rem' }}/>
                                <br/>
                                <p>The Grand Temple at Grand Lodge London</p>
                            </Col>
                        </Row>
                        <br/>

                    </Col>
                </Row>
            </Container>
            {/*{useQuery().get("val")}*/}
        </div>
    );
};
export default WhereWeMeet;
//file: src/webpages/membershipCommitments.js
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Trowel from "../images/trowel.png";
import Header from "./header";

const MembershipCommitments = () => {

    return (
        <div>
            <Header/>
            <Container>
                <Row>
                    <Col><p> </p></Col>
                </Row>
                <Row>
                    <Col  md={2} ><Image src={Trowel} style={{ width: '12rem', height: '10rem' }}/></Col>
                    <Col md={10}>
                        <h1>Membership Commitments</h1>
                        <br/>

                        <p align='justify'>
                            There are certain commitments to being a member of a Masonic Lodge and as members we take them very seriously.
                            There are commitments of both time and money and this sections sets out what they are.
                        </p>
                        <h2>Time Commitments</h2>
                        <h3 align="left">Regular Meetings</h3>
                        <p align="justify">
                            We meet on the third Saturday of October, January, March and May at 3 pm at Mark Mason's Hall 86 St James Street, London (nearest tube Green Park).
                            At the bare minimum you'll be expected to attend these meetings which are referred to as 'Regular' meetings.  Why regular?  Well, they're held at
                            the same time, come what may.  As they always fall on the third Saturday you can put these in your calendar way in advance and plan your time so
                            you are always able to attend.  We always understand that family and work emergencies come first but in general there's a strong expectation that
                            members attend these meetings without fail.
                        </p>
                        <h3 align="left">Lodge of Instruction</h3>
                        <p align="justify">
                            In addition to the regular meetings, we hold an Lodge of Instruction before each meeting.  We meet at Freemason's Hall, normally on the Wednesday
                            evening before the meeting at around 7pm.  These meetings are less formal and regular and we try and accomodate the attendees needs.  The meeting
                            is for the purpose of instruction of members who are currently in office.  New members usually take up office after a few years of joining and
                            wouldn't normally be expected to attend unless they are keen to progress at a faster rate.  Once in office, progression through the offices of
                            the Lodge is normally a 7 year process with more and more things to learn for each role so attendance is essential.  In former days we would hold
                            two or three LOIs prior to a meeting.  We now try and do these online and use technologies such as Google Hangouts or Zoom to video conference.
                        </p>
                        <h3 align="left">Other things</h3>
                        <p align="justify">
                            The above commitments are the minimum you'd need to commit to if you want to be a Freemason.  On top of that many of our members join our Chapter.
                            This is far less time consuming in terms of the length of the meeting and the preparation needed for it.  Members are encouraged to join a chapter
                            once they've made sufficient progress in thier mother Lodge and different chapters have different commitments.  Lewis Chapter meets on the fourth
                            Saturday following the regular lodge meeting but only meets 3 times a year instead of 4.  Many of our members also visit other lodges.  This is a
                            great thing to do since you get to branch out and meet lots of other masons.  Visiting is entirely voluntary but once you commit to attending
                            another lodge you must attend.
                        </p>
                        <h2>Financial Commitments</h2>
                        <p align="justify">
                            Despite the name Freemasonry is far from free.  The following fees apply;
                        </p>
                            <ul>
                                <li align="justify">
                            Initiation £ and is a one off fee on joining.
                                </li>
                                <li align="justify">
                            Annual subscription £ these fees are annual and due on or before the first meeting in October.  They cover membership and dinning for the year.  There are no refunds in the event of non attendance for either pro ratered membership or dinning.
                                </li>
                                <li align="justify">
                            Travel and out of pocket expenses - These vary depending on where you live but you should budget for travel to and from the lodge meeting and LOIs.  Don't forget to include parking and tube if you come by car because parking in Mayfair is expensive and its often better to park on the outskirts and complete the rest of the journey buy tube.  You should also consider congestion and emission charges if you drive in.  Assuming your not driving and intend to enjoy the meeting or LOI to the full then the cost of drinks and often a meal at the LOI needs to be factored in.
                                </li>
                            </ul>
                            <p align="justify">
                            Chapter membership and visiting other lodges is entirely voluntary so I won't cover it in detail here but generally the membership fees for chapter are much lower.  Visiting other lodges depends on the circumstances of the visit.  Often when you're invited to visit the host will pay your dining fees and that sometimes includes wine.  There are some very big, affluent lodges that wine and dine in style offering champagne receptions, gourmet menus with wines matched to each course.  These can be very expensive but worth attending.
                            </p>
                            <p align="justify">
                            Finally, most lodges including this one aspire to hold a Ladies Festival at the end of each year.  This is to honour member's wives in recognition for putting up with our membership.  Its also a good opportunity to introduce prospective candidates to the lodge it being open to all. We haven't held one of these for a few years now at Lewis Lodge for various reasons but when we did, we'd book a hotel for the weekend and hold an evening dinner and dance.  This is something we'd like to bring back and it's generally cost around £200 per person to hold such an event.
                            </p>
                    </Col>
                </Row>

            </Container>
            {/*{useQuery().get("val")}*/}
        </div>
    );
};
export default MembershipCommitments;
//file: src/webpages/home.js
import React from 'react';
import { AmplifySignOut} from "@aws-amplify/ui-react";
import {Link} from "react-router-dom";
import CardGroup from "react-bootstrap/CardGroup";
import Card from "react-bootstrap/Card";
import Acacia from "../images/Acacia.png";
import AllSeeingEye from "../images/all_seeing_eye.png";
import SquareAndCompass from "../images/square_and_compass.png";
import Trowel from "../images/trowel.png";
import G from "../images/G.png";
import PlumbRule from "../images/plumb_rule.png";
import Header from "./header";
//styling from https://stackoverflow.com/questions/53758946/spacing-and-margin-utility-in-react-bootstrap
const Home = () => {

    return (
        <div>
            <Header/>
            <CardGroup className="mx-2 my-2">
                <Card >
                    <Card.Body>
                        <Card.Title>Join Lewis Lodge</Card.Title>
                        <Link to="/join"><Card.Img variant="top" src={Acacia} style={{ width: '20rem', height: '18rem' }}/></Link>
                        <Card.Text>
                            Lewis Lodge are actively recruiting members.  Follow this link to find out more about membership.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Lewis Lodge</Card.Title>
                        <Link to="/lewisLodge"><Card.Img variant="top" src={AllSeeingEye} style={{ width: '18rem', height: '18rem' }}/></Link>
                        <Card.Text>
                            Lewis Lodge is a Masonic Lodge based in London.  Founded on the 17th August 1867 it is a
                            founding lodge of the Metropolitan Grand Lodge of London.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>What is Freemasonry</Card.Title>
                        <Link to="/whatIsFreemasonry"><Card.Img variant="top" src={SquareAndCompass} style={{ width: '18rem', height: '18rem' }}/></Link>
                        <Card.Text>
                            Freemasonry is an institution founded on the
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
            <CardGroup className="mx-2 my-2">
                <Card>
                    <Card.Body>
                        <Card.Title>Membership Commitments</Card.Title>
                        <Link to="/membershipCommitments"><Card.Img variant="top" src={Trowel} style={{ width: '20rem', height: '18rem' }}/></Link>
                        <Card.Text>
                            Find out what you'll be signing up for when you become a member of Lewis Lodge.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Where we meet</Card.Title>
                        <Link to="/whereWeMeet"><Card.Img variant="top" src={G} style={{ width: '18rem', height: '18rem' }}/></Link>
                        <Card.Text>
                            We meet and dine in some of London's most beautiful and historic locations.
                            We can't show you too much but we can give you a flavour.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Masonic Values</Card.Title>
                        <Link to="/masonicValues"><Card.Img variant="top" src={PlumbRule} style={{ width: '18rem', height: '18rem' }}/></Link>
                        <Card.Text>
                            Learn what we're all about.  You'll find out what it means to be a Freemason and what we hold close to our hearts.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
    );
};
export default Home;
import React, {  createContext, useState } from 'react';
import './App.css';
import Webpages from './webpages';
import './custom.scss';
import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";

Amplify.configure(awsconfig);

export const AuthContext = createContext();


function App() {

  const [mode, setMode] = useState( 'signup');

  return (
      <div className="App" >
        <AuthContext.Provider value={[mode, setMode]}>
            <Webpages />
        </AuthContext.Provider>
      </div>
  );
}

export default App;

//file: src/webpages/masonicvalues.js
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import PlumbRule from "../images/plumb_rule.png";
import Header from "./header";

const MasonicValues = () => {

    return (
        <div>
            <Header/>
            <Container>
                <Row>
                    <Col><p> </p></Col>
                </Row>
                <Row>
                    <Col  md={2} ><Image src={PlumbRule} style={{ width: '12rem', height: '10rem' }}/></Col>
                    <Col md={10}>
                        <h1>Masonic Values</h1>
                        <br/>

                        <p align='justify'>
                            For Freemasons, there are four important values that help define their path through life: Integrity, Friendship, Respect and Charity.
                            In today’s world filled with uncertainty, these principles ring as true now as they have at any point in the organisation’s history.
                        </p>
                        <h2 align={'left'}>Integrity</h2>
                        <p align="justify">
                            Building good people Freemasons are focused on building themselves as people of integrity, and membership provides the structure to help
                            achieve that goal. Being a Freemason gives members a sense of purpose, supporting and guiding them on their journey through life. Collectively,
                            members are bonded through an understanding of unity and equitability – principles fundamental to Freemasonry.
                        </p>
                        <h2 align={'left'}>Friendship</h2>
                        <p align="justify">
                            Building togetherFreemasonry provides the common foundation for friendships between members, many of which will last for life. Being a Freemason
                            means something different to each person who joins, but whether looking to make acquaintances or develop their own potential, all members share
                            a sense of togetherness that strengthens their ability to succeed and grow.
                        </p>
                        <h2 align={'left'}>Respect</h2>
                        <p align="justify">
                            Building unityFreemasonry brings people together irrespective of their race, religion, or other perceived differences that can divide us as a society.
                            Members are expected to be of high moral standing and are encouraged to talk openly about what the organisation does and what it means to be part of
                            it.
                        </p>
                        <h2 align={'left'}>Charity</h2>
                        <p align="justify">
                            Building compassion Kindness and charitable giving are deeply ingrained within the principles of Freemasonry and the organisation provides the structure
                            for members to make positive contributions to their communities and various causes through fundraising events or volunteer work. Individuals can make an
                            important contribution at local, national and global level by giving both their time and money.
                        </p>
                    </Col>
                </Row>

            </Container>
            {/*{useQuery().get("val")}*/}
        </div>
    );
};
export default MasonicValues;
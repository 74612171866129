//file: src/webpages/join.js
import React from 'react';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {AuthContext} from "../App";

import Acacia from "../images/Acacia.png";

function useQuery() {
    return new URLSearchParams(window.location.search);
}

const Join = () => {
    const [mode,setMode] = React.useContext(AuthContext);
    return (
        <div>
            <Container>
                <Row>
                    <Col><p> </p></Col>
                </Row>
                <Row>
                    <Col  md={2} ><Image src={Acacia} style={{ width: '15rem', height: '13rem' }}/></Col>
                    <Col md={10}>
                        <h1>Join Lewis Lodge</h1>
                        <br/>
                        <p align='left'>Have you ever thought of becoming a Freemason?  Do you know what it means to be a Freemason?  Well, by following this link you've
                            embarked on the first step of a journey that could lead you to becoming a member of our Lodge.</p>
                        <p align="left">
                            So, what's it all about?  There's a great deal of information about Freemasonry online, and there's the problem.  There's too much information.
                            Freemasonry is such a vast and complex organisation its easy to get the wrong idea or no idea from this deluge of data.  Its hard enough
                            to understand it when your a member.  In fact I've been a member for 15 years now and I find most of it unfathomable!
                        </p>
                        <p align="left">
                            Why am I a member then?  Well, I love it.  I get out of it what most masons get out of it and that's primarily being a part of a fraternity. I
                            always wanted a brother, now I have millions! Its an odd institution in this day and age but that forms part of the attraction.  Its very formal
                            and traditional whilst being fun at the same time.  Most of all though membership allows you to socialise in an atmosphere of complete trust and
                            integrity with other like minded brothers.
                        </p>

                        <p align="left">
                            You meet people from all walks of life in Freemasonry.  Our Lodge is mainly made up of stock brokers, bankers and IT professionals but we also
                            have representation of professions and trades such as teaching and logistics.  We have a broad representation in terms of nationality and religious
                            beliefs too, having members who are Christian, Sikh, Muslim and Jewish from countries such as England, France, Germany and Lebanon although I hasten
                            to add discussion of politics and religion are prohibited in Lodge.
                        </p>

                        <p align="left">
                            We all come together officially 4 times a year.  Its often more than that but 4 times a year is what you'd have to commit to if you want to join.
                            When we do, we meet up at our Lodge in Mayfair.  We meet at the bar before holding our meeting in the Temple normally lasting for around 2-3
                            hours depending on what we're doing.  After that its back to the bar and then onto a formal dinner that we call the Festive Board.  This goes on
                            for a few hours and the food and drink are good.  After joining and making sufficient progress you get to visit other lodges who do exactly the
                            same thing.  It opens up a whole new world.
                        </p>

                        <p align="left">
                            It's not cheap.  There are annual membership fees, dinning fees, travel expenses and every time we meet we put into the charity pot.
                            Charity is something that's very close to our hearts.  We're a sociable lot too so you'd need to account for that.  I believe our younger
                            members often continue into the night following a meeting.
                        </p>

                        <p align="left">
                            If you want to find out more, then please register.  The registration process will collect some personal details
                            that we'll use to contact you and you'll also be able to access more information about what we're all about.
                        </p>

                        <p align="left">I look forward to speaking with you soon. </p>

                        <p align="left">Fraternally </p>

                        <p align="left">Brother Membership Secretary </p>

                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col><div className="d-flex justify-content-end">
                        <Button variant="primary" href='/home?from=join' >Register</Button>
                    </div></Col>
                </Row>
            </Container>
            {/*{useQuery().get("val")}*/}
        </div>
    );
};
export default Join;
//file: src/webpages/index.js
//routing and auth from example at https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    Outlet,
    useLocation
} from "react-router-dom";

import Home from './home';
import Login from './login';
import LewisLodge from './lewisLodge';
import Join from './join';
import WhatIsFreemasonry from './whatIsFreemasonry';
import MasonicValues from './masonicValues';
import MembershipCommitments from './membershipCommitments';
import WhereWeMeet from './whereWeMeet';
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import {AuthContext} from "../App";

const Webpages = () => {

    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    function useAuth() {
        return authState === AuthState.SignedIn && user;
    }

    function PrivateRoute() {
        const auth = useAuth();
        const location = useLocation();
        const [mode, setMode] = React.useContext(AuthContext);
        const from = new URLSearchParams(location.search).get('from');
        !auth && (from === "join")? setMode("signup"):setMode("signin");
        return auth ? <Outlet/> : <Navigate to="/login" state={{ from: location.pathname, authState: auth }} />;
    }

    return(
        <Router>
            <Routes>
                <Route path = "/join" element = {<Join/>} />
                <Route path = "/login" element = {<Login/>} />
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Home/>} />
                    <Route path="/home" element={<Home/>} />
                    <Route path="/lewisLodge" element={<LewisLodge />} />
                    <Route path="/masonicValues" element={<MasonicValues />} />
                    <Route path="/membershipCommitments" element={<MembershipCommitments />} />
                    <Route path="/whereWeMeet" element={<WhereWeMeet />} />
                    <Route path="/whatIsFreemasonry" element={<WhatIsFreemasonry />} />
                </Route>
            </Routes>
        </Router>
    );
};
export default Webpages;
//file: src/webpages/Login.js
import React from 'react';
import { Auth } from 'aws-amplify';
import {AmplifyAuthenticator,AmplifyAuthContainer,AmplifySignUp} from "@aws-amplify/ui-react";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import {Navigate, useLocation} from "react-router-dom";
import {AuthContext} from "../App";

const Login = () => {

    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const [mode, setMode] = React.useContext(AuthContext);
    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    function useAuth() {
        return authState === AuthState.SignedIn && user;
    }

    const location = useLocation()

    const handleSignUp = async (formData) => {

        let authAttributes = Object.assign({}, formData.attributes);

        const param = {
            ...formData,
            attributes: {...formData.attributes},
        }
        const data = await Auth.signUp(param);

        console.log(data.user);
        // if successful submit persist authAttributes to backend

        return data;
    }

    return useAuth()?<Navigate to={location.state.from}  />:
        <AmplifyAuthContainer>
            <AmplifyAuthenticator initialAuthState={mode}  usernameAlias="email">
                <AmplifySignUp slot="sign-up"  usernameAlias="email" handleSignUp={handleSignUp}
                               formFields={[
                                   {
                                       type: "email",
                                       label: "Email",
                                       placeholder: "email",
                                       inputProps: { required: true, autocomplete: "username" },
                                       required:true,
                                   },
                                   {
                                       type: "password",
                                       label: "Password",
                                       placeholder: "password",
                                       inputProps: { required: true, autocomplete: "new-password" },
                                       required:true,
                                   },
                                   {
                                       type: "phone_number",
                                       label: "Phone Number",
                                       placeholder: "Phone Number",
                                       dialCode: "+44",
                                       required:true,
                                   },
                               ]}
                />
                />
            </AmplifyAuthenticator>
        </AmplifyAuthContainer>

};
export default Login;
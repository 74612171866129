//file: src/webpages/lewisLodge.js
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import AllSeeingEye from "../images/all_seeing_eye.png";
import RMIForBoys from "../images/rmi_for_boys_wood_green.png";
import OxfordCornerHouse from "../images/oxford_corner_house.jpg";
import Header from "./header";


const LewisLodge = () => {
    return (
        <div>
            <Header/>
            <Container>
                <Row>
                    <Col><p> </p></Col>
                </Row>
                <Row>
                    <Col  md={3} >
                        <Image src={AllSeeingEye} style={{ width: '15rem', height: '13rem' }}/>
                        <br/>
                    </Col>
                    <Col md={9}>
                        <h1>Lewis Lodge 1185</h1>
                        <br/>
                        <p align='justify'>
                            The Lewis Lodge No 1185 is a masonic lodge founded in 1867 and sponsored by the Westiminister and Keystone Lodge No 10 by a number of existing Freemasons
                            or 'Bretheren' who lived in and around the village of Wood Green in North London.   The lodge was originally founded on the principal objective to render
                            support to the Masonic Charities and especially to the Royal Masonic Institution for the sons of decayed and deceased Freemasons.
                        </p>
                        <Row>
                        <Col md={6}>
                            <p align="justify">
                            <a href={"http://www.childrenshomes.org.uk/RoyalMasonicBoys/"} target={"_blank"}>The Royal Masonic Institution for Boys</a> , a school who's purpose was to provide for the orphaned boys of freemasons was originally sited in Wood Green.
                            The lodge's purpose was therefore to look after the school and its pupils. In its early years, lodge meetings would start an hour early during which
                            time the members inspected the school.  The lodge would then meet at the Nightingale Tavern, about 500 yards for the regular meeting and ceremony.
                            </p>
                        </Col>
                        <Col md={6}>
                            <Image src={RMIForBoys} style={{ width: '15rem', height: '13rem' }}/>
                            <br/>
                            <p>Royal Masonic Institute for Boys</p>
                        </Col>
                        </Row>
                        <br/>
                        <p align="justify">
                            The lodge grew over the following ten years and during that period initiated 92 candidates.  The Nightingale Tavern became too small to hold the meetings
                            so the lodge relocated to the King's Arms Hotel where it stayed until 1897.  The King's Arms Hotel saw a change of ownership at that point and no longer
                            wanted to continue as a venue for the meetings.  Since the membership had now drawn from a greater geographical spread many members coming from all parts of
                            the metropolitan area the lodge decided to relocate to the Holborn Restaurant and has stayed there until 1954.
                        </p>
                        <Row>
                            <Col md={6}>
                                <Image src={OxfordCornerHouse} style={{ width: '15rem', height: '13rem' }}/>
                                <br/>
                                <p>The Oxford Corner House</p>
                            </Col>
                            <Col md={6}>
                                <p align="justify">
                                    Following the demolition of the Holborn Restaurant meetings were held in the Oxford Corner House in Tottenham Court Road until 1966.
                                    The lodge then moved to the Kensington Palace Hotel.  Since then the lodge has remained in the London Metropolitan area and moved
                                    location several times.  We now meet at Mark Mason's Hall, 86 St James Street.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {/*{useQuery().get("val")}*/}
        </div>
    );
};
export default LewisLodge;
import React from "react";
import {Nav, Navbar, Container, Offcanvas, Image, NavItem} from "react-bootstrap";
import Lewis from "../images/lewis.png";
import MiniProspect from "../images/mini_prospect.png";
import MiniProspect_clicked from "../images/mini_prospect_clicked.png";

import {AmplifySignOut} from "@aws-amplify/ui-react";

export default function Header() {
    const [miniProspect,setMiniProspect] = React.useState(MiniProspect)
    return (
        <Navbar bg="primary" expand={false}>
            <Container fluid>
                <Navbar.Brand href="/home">
                    <img
                        alt=""
                        src={Lewis}
                        width="42"
                        height="49"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Nav className="ms-auto">
                    <NavItem >
                    <AmplifySignOut />
                    </NavItem>
                </Nav>
                <Nav className="ms-auto">
                    <NavItem >
                        <Nav.Link draggable={'false'}
                                  onMouseDown={()=> setMiniProspect(MiniProspect_clicked)}
                                  onMouseOut={()=> setMiniProspect(MiniProspect)}
                                  href="/profile">
                            <Image draggable={'false'} src={miniProspect} width={50} height={50} />
                        </Nav.Link>
                    </NavItem>
                </Nav>
                <Navbar.Toggle aria-controls="offcanvasNavbar" />
                <Navbar.Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarLabel">Lewis 1185</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav.Link href="/home">Home</Nav.Link>
                            <Nav.Link href="/lewisLodge">Lewis Lodge</Nav.Link>
                            <Nav.Link href="/masonicValues">Masonic Values</Nav.Link>
                            <Nav.Link href="/membershipCommitments">Membership Commitments</Nav.Link>
                            <Nav.Link href="/whereWeMeet">Where we meet</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}
